#contact {
	padding: $sectionPadding;
	background: $thirdGradient;
}

#contact .contact-item {
	margin: 20px 0;
}

#contact .fa {
	color: $contrastBFont;
	font-size: 20px;
}

#contact .contact-item span {
	color: rgba(255, 255, 255, 1);
	margin-bottom: 10px;
	display: block;
}

#contact .contact-item i.fa {
	margin-right: 10px;
}

#contact .social {
	border-top: 1px solid $colorF;
	padding-top: 50px;
	margin-top: 20px;
	text-align: center;
}

#contact .social ul li {
	display: inline-block;
	margin: 0 20px;
}

#contact .social i.fa {
	font-size: 22px;
	width: 48px;
	height: 48px;
	padding: 12px 0;
	border: 2px solid $contrastBFont;
	color: $contrastBFont;
	border-radius: 50%;
	transition: all 0.3s;
}

#contact .social i.fa:hover {
	color: $colorF;
	background: $contrastBFont;
}

#contact li {
	text-align: center;
}

#contact li:before {
	content: "";
}
