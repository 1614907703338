#recommendation {
	padding: $sectionPadding;
	background: $thirdGradient;
}

#recommendation .highlight-button {
	text-transform: uppercase;
	color: #fff;
	background-color: $contrastBFont;
	padding: 14px 34px;
	letter-spacing: 3px;
	font-size: 16px;
	font-size: 1em;
	border-radius: 25px;
	transition: all 0.5s linear;
}

#recommendation .highlight-button:hover,
#recommendation .highlight-button:focus,
#recommendation .highlight-button.focus,
#recommendation .highlight-button:active,
#recommendation .highlight-button.active {
	color: #fff;
	background-image: none;
	background-color: $contrastCFont;
}

#recommendation hr {
	background: $contrastBFont;
}

#recommendation a {
	text-decoration: solid;
}
