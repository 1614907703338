#support-areas {
	background: #f6f6f6;
	padding: $sectionPadding;
}

#support-areas p {
	font-size: 18px;
}

#support-areas h3 {
	position: relative;
	margin-top: 15px;
	margin-bottom: 20px;
	padding: 2px 0;
	font-weight: 500;
}

#support-areas h3::after {
	position: absolute;
	content: "";
	background: $thirdGradient;
	height: 2px;
	width: 60px;
	bottom: 0;
	left: 0;
}

#support-areas li {
	text-align: justify;
}

#support-areas li:before {
	content: "\f111";
	font-family: "FontAwesome";
	color: $otherBrightColor;
	font-size: 10px;
	vertical-align: middle;
	padding-right: 10px;
}
