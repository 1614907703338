$primaryColor: #7a9f79;
$secondaryColor: #c9d5c2;
$darkColor: #315e26;

$otherContrastColor: #d79579;
$otherBrightColor: #ddc1af;

$whiteColor: #fff;
$colorA: #dfcc9d;
$colorB: #a4b3a0;
$colorC: #e0edd8;
$colorD: #315e26;
$colorE: #315a39;
$colorF: #e3cbaa;
$colorG: #f6ede1;

$semiTransparentColor: rgba(255, 255, 255, 0.5);
$semiDarkTransparentColor: rgba(0, 0, 0, 0.3);

$primaryGradient: linear-gradient(
	to right,
	$primaryColor 30%,
	$secondaryColor 85%
);
$secondaryGradient: linear-gradient(
	to right,
	$otherContrastColor 5%,
	$otherBrightColor 55%
);
$thirdGradient: linear-gradient(to right, $colorF 5%, $colorG 55%);
$gradientD: linear-gradient(to right, #bdc49d 5%, #d2daaf 65%);

$fontStack: "Crimson Pro", serif;
$headerFontStack: $fontStack;
$textFontSize: 18px;
$textFontWeight: 400;

$whiteFont: #fff;
$grayFont: #777;
$contrastFont: #a16f65;
$contrastBFont: #9e6b61;
$contrastCFont: #ba968b;
$contrastDFont: #b1887d;

$sectionPadding: 70px 0;

:export {
	primaryColor: $primaryColor;
	fontStack: $fontStack;
	textFontSize: $textFontSize;
}
