#about {
	padding: $sectionPadding;
}

#about p {
	line-height: 30px;
	margin: 30px 0;
}

#about h2::after {
	background: $thirdGradient;
}

#about img {
	float: left;
}
