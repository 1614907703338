#price-list {
	padding: $sectionPadding;
	background: $thirdGradient;
}

#price-list h1 {
	color: $contrastBFont;
	font-size: 44px;
	font-size: 2.5em;
	text-align: left;
}

#price-list li {
	font-size: 20px;
	font-size: 1.15em;
	text-align: justify;
	padding-bottom: 5px;
}

#price-list li:before {
	content: "\f111";
	font-family: "FontAwesome";
	color: $otherBrightColor;
	font-size: 8px;
	vertical-align: middle;
	padding-right: 8px;
}

#price-list hr {
	background: $contrastBFont;
}
