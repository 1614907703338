#intro {
	padding: $sectionPadding;
	background: #f6f6f6;
	background: $thirdGradient;
}

#intro h3 {
	position: relative;
	margin-top: 15px;
	margin-bottom: 20px;
	padding: 2px 0;
	font-weight: 500;
}

#intro h3::after {
	position: absolute;
	content: "";
	background: $secondaryGradient;
	height: 2px;
	width: 60px;
	bottom: 0;
	left: 0;
}

#intro hr {
	background: $contrastBFont;
}

#intro h1 {
	color: $contrastBFont;
	font-size: 44px;
	font-size: 2.5em;
}
