#menu {
	padding: 5px;
	transition: all 0.8s;
}

#menu.navbar-default {
	background-color: #fff;
	border-color: rgba(231, 231, 231, 0);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

#menu a.navbar-brand {
	font-size: 24px;
	font-weight: 700;
	color: #333;
	text-transform: uppercase;
}

#menu.navbar-default .navbar-nav > .nav-link {
	text-transform: uppercase;
	color: #555;
	font-size: 16px;
	font-weight: 400;
	border-radius: 0;
	margin: 0 5px;
}

#menu.navbar-default .navbar-nav > a:hover,
#menu.navbar-default .navbar-nav > a:after,
#menu.navbar-default .navbar-nav > a:focus {
	color: $contrastBFont;
	border-bottom: 1px solid $contrastBFont;
}
